<template>
  <div class="table">
    <VueGoodTable
      v-if="$mq === 'lg'"
      :columns="columns"
      :rows="rows"
      :pagination-options="{ enabled: false }"
      :sort-options="{ enabled: true, initialSortBy: initialSortBy }"
      compactMode
      @on-sort-change="$emit('sort', $event)"
    >
      <template slot="table-row" slot-scope="props">
        <slot name="table-row" :row="props.row" :column="props.column" :index="props.index">
          <span>{{ props.row[props.column.field] || "" }}</span>
        </slot>
      </template>
      <span slot="emptystate" class="vgt-empty">
        <slot name="empty">Данных нет</slot>
      </span>
    </VueGoodTable>
    <div class="table-list" v-else>
      <ul :class="listClass">
        <li v-for="(row, i) in rows" :key="i">
          <slot name="table-m-item" :row="row" :index="i"></slot>
        </li>
      </ul>
      <slot name="table-m-footer"></slot>
    </div>
    <PaginationComponent
      v-if="page !== null && total !== null && pagination && total > 11"
      :total="Math.ceil(total / 11)"
      @change="$emit('paginate', $event)"
      :page="page"
    />
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import PaginationComponent from "@/components/Pagination";

export default {
  name: "Table",
  props: {
    total: {
      type: Number,
      default: null,
    },
    initialSortBy: Array,
    page: {
      type: Number,
      default: null,
    },
    columns: Array,
    rows: Array,
    pagination: Boolean,
    listClass: {
      type: String,
      default: "",
    },
  },
  components: {
    PaginationComponent,
    VueGoodTable,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/table"
</style>
