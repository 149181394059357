<template>
  <ul class="pagination">
    <li v-if="page !== 1">
      <button type="button" class="pagination__btn" @click="$emit('change', 1)">
        <ChevronLeftIcon />
      </button>
    </li>
    <li v-for="(p, i) in currentFive" :key="i">
      <button
        type="button"
        class="pagination__btn"
        @click="$emit('change', p)"
        :class="{ 'pagination__btn--active': page === p }"
      >
        {{ p }}
      </button>
    </li>
    <li v-if="page !== total">
      <button type="button" class="pagination__btn" @click="$emit('change', total)">
        <ChevronRightIcon />
      </button>
    </li>
  </ul>
</template>

<script>
import ChevronLeftIcon from "@/components/svg/ChevronLeftIcon";
import ChevronRightIcon from "@/components/svg/ChevronRightIcon";

export default {
  name: "PaginationComponent",
  props: {
    page: Number,
    total: Number,
  },
  computed: {
    currentFive() {
      let startPage, endPage;
      let maxPages = 5;
      if (this.total <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = this.total;
      } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (this.page <= maxPagesBeforeCurrentPage) {
          // current page near the start
          startPage = 1;
          endPage = maxPages;
        } else if (this.page + maxPagesAfterCurrentPage >= this.total) {
          // current page near the end
          startPage = this.total - maxPages + 1;
          endPage = this.total;
        } else {
          // current page somewhere in the middle
          startPage = this.page - maxPagesBeforeCurrentPage;
          endPage = this.page + maxPagesAfterCurrentPage;
        }
      }
      return Array.from(Array(endPage + 1 - startPage).keys()).map((i) => startPage + i);
    },
  },
  components: {
    ChevronRightIcon,
    ChevronLeftIcon,
  },
};
</script>

<style lang="stylus">
.pagination {
  display flex
  justify-content center
  align-items center
  margin-top 24px
  list-style none

  &__btn {
    background: var(--main_white);
    border-radius: var(--button_small_radius);
    width 44px
    height 44px
    display flex
    justify-content center
    align-items center
    border 1px solid transparent
    box-sizing border-box
    font-weight: normal;
    font-size: 0.8750em;
    line-height 20px;
    text-align: center;
    outline none
    letter-spacing: var(--letter_spacing);
    color: var(--main_light_gray);
    cursor pointer

    &--active {
      border: 1px solid var(--input_focused_border);
      color var(--main_gray)
      font-weight 500
    }

    .icon {
      width 16px
      height 16px

      svg path {
        stroke var(--main_light_gray)
      }
      }
    }
  }
</style>
